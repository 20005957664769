

body {
  visibility: visible !important;
}

h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.hide {
  display:none;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

 .navli {
  display: inline !important;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  align-items: stretch !important;
  display: flex !important;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}



.modal-background {
  overflow: auto !important;
  position: fixed !important;
  top: 20 !important;

}


.modal .animation-content .modal-card {
  overflow: visible !important;

}

.modal-card-head {
    margin-top: 5em;

}


.modal-card {
  overflow: visible !important;

}


.navbar-item-img-max-height {
  size: 200rem !important;
}

.pageBorder {border-bottom: 1px solid #d7e5f0; margin-bottom:10px;}

.studioLink {
  color: rgb(5, 4, 4);
}
.studioLink:hover {
  text-decoration: underline;
  color:rgba(0, 102, 204);
}

.checkbox .checkbox .radio .radio {
    margin-right: 1em !important;
}

.checkbox {
  margin-right:1px !important;
}


#ck-button {
  margin:4px;
  background-color: transparent;
  border:1px solid #00d1b2;
  color: #00d1b2;
  overflow:auto;
  float:left;
  border-radius: 10px;
}

#ck-button:hover {
  background:#00d1b2;
  color: #fff;
}

#ck-button label {
  float:left;
  width: 200px;
}

#ck-button label span {
  text-align:center;
  display:block;
  padding:3px 0px;
}

#ck-button label input {
  top:-20px;
  display: none;
}

#ck-button input:checked + span {
  background-color:#00d1b2;
  border:1px solid #ffffff;
  border-radius: 10px;
  color:#fff;
}

.notification {
  background: transparent !important;
  Background: transparent !important;
  background-color: transparent !important;
}

.button {
  margin-left: 0.5em;
}

